import {clientStatuses, clientTypes} from '@/utils/constants';

const pick = require('lodash/pick');
const stringMask = require('string-mask');
const phoneMask = new stringMask('+7 (000) 000 00 00');

const maskPhone = (phone, isFull = true) => {
  if (!phone) {
    return phone
  }
  let toMaskPhone = phone.split('').filter(e => !isNaN(e) && e !== ' ').join('');
  if (isFull) {
    const plusSignIndex = toMaskPhone.split('').findIndex(e => e === '+');
    if (plusSignIndex > -1) {
      toMaskPhone = plusSignIndex.slice(plusSignIndex);
    } else if (toMaskPhone.charAt(0) === '7') {
      toMaskPhone = toMaskPhone.slice(1);
    }
  }
  return phoneMask.apply(toMaskPhone);
}

const rusErrorText = {
  'Validation isEmail on email failed': 'Проверьте email',
  'email must be unique': 'Этот email уже зарегестрирован',
  'phone must be unique': 'Этот номер уже зарегестрирован',
  'phone is not valid phone number': 'Некорректный номер телефона',
  'Pass dateStart correct.': 'Дата начала подписки некорректна',
  'Pass all data correct.': 'Данные по подписке некорректны',
  'Pass correct days': 'Выберите все дни для подписки',
  'Too much bonuses': 'У клиента нет столько бонусов',
  'Can not send sms': 'Не удалось отправить сообщение с паролем',
  'Invalid data': 'Заполните поля корректно'
}

const getErrorText = (error) => {
  if (Array.isArray(error)) {
    return error.map(errorText => rusErrorText[errorText] || errorText).join('\n')
  }

  if (!rusErrorText[error]) {
    console.warn('Нет перевода для ошибки', error) // eslint-disable-line
    return ''
  }

  return rusErrorText[error]
}

const toInitial = val => (String(val) || '').charAt(0).toUpperCase()

const toSurnameWithInitials = (val) => {
  let res = ''

  if (val.surname) {
    res = val.surname
  }
  if (val.name) {
    res += ` ${toInitial(val.name)}.`
  }
  if (val.patronymic) {
    res += ` ${toInitial(val.patronymic)}.`
  }

  if (!res) {
    res = val?.phone || '-'
  }
  return res
}

// TODO: move outside of component to more generic approach along with drivers
const pickUserData = (form) => {
  return pick(form, [
    'id', 'email', 'surname', 'name', 'patronymic', 'phone', 'status', 'role', 'type', 'managerId', 'residentialAddress'
  ])
}

const pickLegalProfileData = (form) => {
  return pick(form, [
    'account', 'bankName', 'bik', 'corrAccount', 'legalAddress', 'legalName', 'postmailAddress', 'responsibleFIO', 'vat',
    'bankOGRN', 'bankOKTMO', 'bankOKPO', 'bankKPP', 'bankINN', 'legalType', 'residentialAddress', 'inn'
  ])
}

const pickDriverData = (form) => {
  return pick(form, [
    'dob', 'document', 'carNumber', 'carModel', 'bodyType', 'tempFrom', 'tempTo', 'licenseDocument', 'legalName', 'inn',
    'ogrnip', 'account', 'bankName', 'bik', 'corrAccount', 'bankKPP', 'bankINN', 'bankOKPO', 'bankOGRN', 'bankOKTMO',
    'autoLength', 'autoWidth', 'autoHeight', 'paletteEUR', 'paletteFIN', 'paletteUSA', 'documentImageId', 'licenseImageId',
    'carWithHydro', 'mkadPass', 'ttkPass', 'skPass', 'loadingHelpAvailable', 'legalType',
    'passportSeries', 'passportNumber', 'passportWhomIssued', 'passportWhenIssued', 'weight', 'residentialAddress'
  ])
}

const pickProfileData = (form) => {
  return {
    id: form.profileId,
    ...(form.type === 'legal' ? pickLegalProfileData(form) : {}),
    ...(form.role === 'driver' ? pickDriverData(form) : {})
  }
}

const debounce = (fn, wait) => {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    const context = this;
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, wait);
  }
}

const formatUserStatus = (val) => {
  return clientStatuses[val] || '-';
}

const formatClientType = (val) => {
  return clientTypes[val] || '-';
}

const formatClientName = (val) => {
  let result = ''
  if (val?.surname || val?.name || val?.surname || val?.legalProfile?.legalName) {
    result += (val.type === 'physical' ? `${val.surname || ''} ${val.name || ''} ${val.patronymic || ''}` : (val?.legalProfile?.legalName || '')).trim()
  } else if (val?.phone) {
    result = maskPhone(val.phone)
  } else {
    result += '-'
  }
  return result
}

const onlyPositiveNumbers = (evt) => {
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 44) {
    evt.preventDefault();
  } else {
    return true;
  }
}

export {
  getErrorText,
  maskPhone,
  toInitial,
  toSurnameWithInitials,
  pickUserData,
  pickProfileData,
  pickLegalProfileData,
  pickDriverData,
  debounce,
  formatUserStatus,
  formatClientName,
  formatClientType,
  onlyPositiveNumbers
}
